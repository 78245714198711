import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend,
 LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer} from 'recharts';
import Loader from './Loader';
import {Loader2} from  'lucide-react';
import './table.css';
import Text from './Text';

function App() {
  const [sentimentData, setSentimentData] = useState([]);
  const [wordCloudImage, setWordCloudImage] = useState('');
  const [datatable, setDatatable] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [subreddit, setSubreddit] = useState('');
  const [timeFilter, setTimeFilter] = useState('all');
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [insight, setInsight] = useState('')

  const COLORS = ["#2ccce4", "#14104a", "#bed3f3"];
  const SENTIMENT_COLORS = {
    POS: "#bed3f3",
    NEG: "#2ccce4",
    NEU: "#14104a"
  };
  // Function to process time series data
  const processTimeSeriesData = (data, timeFilter) => {
    if (!data || data.length === 0) return [];

    // Convert string dates to timestamps
    const timestamps = data.map(item => new Date(item.Time).getTime());
    const minTime = Math.min(...timestamps);
    const maxTime = Math.max(...timestamps);
    let interval;
    let bucketCount;

    switch (timeFilter) {
      case 'yesterday':
        // For yesterday, create 24 hourly buckets
        bucketCount = 24;
        interval = (24 * 60 * 60 * 1000) / bucketCount; // One hour intervals

        break;

      case 'last_month':
        // For last month, create daily buckets
        bucketCount = 10;
        interval = (3 * 24 * 60 * 60 * 1000); // One day intervals

        break;

      case 'last_year':
        // For last year, create monthly buckets
        bucketCount = 12;
        interval = (30 * 24 * 60 * 60 * 1000); // Approximate month intervals

        break;

      default: // 'all' time
        // Create 12 evenly distributed buckets
        bucketCount = 12;
        interval = (maxTime - minTime) / bucketCount;

    }

    // Create buckets based on the determined parameters
    const buckets = Array(bucketCount).fill().map((_, i) => ({
      startTime: minTime + (i * interval),
      endTime: minTime + ((i + 1) * interval),
      POS: 1,
      NEG: 1,
      NEU: 1,
      total: 3
    }));

    // Distribute data into buckets
    data.forEach(item => {
      const timestamp = new Date(item.Time).getTime();
      const bucketIndex = Math.floor((timestamp - minTime) / interval);
      if (bucketIndex >= 0 && bucketIndex < bucketCount) {
        buckets[bucketIndex][item.sentiment]++;
        buckets[bucketIndex].total++;
      }
    });
    const formatDate = (timestamp, timeFilter) => {
      const date = new Date(timestamp);
      switch (timeFilter) {
        case 'yesterday':
          return `${date.getHours().toString().padStart(2, '0')}:00`;
        case 'last_month':
          return `${date.getDate()}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
        case 'last_year':
          return date.toLocaleDateString([], { month: 'short', year: 'numeric' });
        default:
          return date.toLocaleDateString([], { month: 'short', year: 'numeric' });
      }
    };
    // Calculate percentages and format dates
    return buckets.map((bucket) => ({
      name: formatDate(bucket.endTime, timeFilter),
      POS: bucket.total > 0 ? (bucket.POS / bucket.total * 100).toFixed(1) : 0,
      NEG: bucket.total > 0 ? (bucket.NEG / bucket.total * 100).toFixed(1) : 0,
      NEU: bucket.total > 0 ? (bucket.NEU / bucket.total * 100).toFixed(1) : 0
    }));
  };

  const downloadCSV = async () => {
    if (!csvData) return;

    try {
      const response = await fetch('/download', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ csv_data: csvData }),
      });

      if (!response.ok) {
        throw new Error('Could not download file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'reddit_data.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  // Initial data fetch with search
  const handleSearch = () => {
    setIsInitialLoading(true);
    fetch('/api/sentiment_counts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        keyword,
        subreddit,
        time_filter: 'all'
      })
    })
    .then((response) => response.json())
    .then((data) => {
      const chartData = Object.keys(data.sentiment_counts).map((key) => ({
        name: key,
        value: data.sentiment_counts[key],
      }));
      setSentimentData(chartData);
      setWordCloudImage(data.wordcloud);
      setDatatable(data.datatable);
      setCsvData(data.csv_data);
      setTimeFilter('all');
      setTimeSeriesData(processTimeSeriesData(data.datatable));
      setInsight(data.insight);
    })
    .finally(() => setIsInitialLoading(false));
  };

  // Handle time filter change - doesn't affect CSV data
  const handleTimeFilterChange = (e) => {
    const newFilter = e.target.value;
    setTimeFilter(newFilter);
    setIsUpdateLoading(true);

    fetch('/api/sentiment_counts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        keyword,
        subreddit,
        time_filter: newFilter
      })
    })
    .then((response) => response.json())
    .then((data) => {
      const chartData = Object.keys(data.sentiment_counts).map((key) => ({
        name: key,
        value: data.sentiment_counts[key],
      }));
      setSentimentData(chartData);
      setWordCloudImage(data.wordcloud);
      setDatatable(data.datatable);
      setTimeSeriesData(processTimeSeriesData(data.datatable, newFilter));
      setInsight(data.insight)
    })
    .finally(() => setIsUpdateLoading(false));
  };

  const total = sentimentData.reduce((sum, entry) => sum + entry.value, 0);

  return (
    <div style={{ backgroundColor: '#faf6f5' ,padding: '20px', fontFamily:'Ubuntu' }}>
      <h1 style={{
      textAlign: 'center', // You can change this to any font you like
      color: '#0B4B97', // Change to your desired color
      fontSize: '2.5rem', // Adjust the size as needed
      margin: '20px 0' // Adjust the margin as needed
    }}>
        Reddit Sentiment Analysis
      </h1>

      {/* Search inputs */}
      <div style={{ display: 'flex', gap: '10px', justifyContent: "center", alignItems: 'center', marginBottom: '10px' }}>
        <input
          type="text"
          placeholder="Keyword"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          style={{
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
          disabled={isInitialLoading}
        />
        <input
          type="text"
          placeholder="Subreddit"
          value={subreddit}
          onChange={(e) => setSubreddit(e.target.value)}
          style={{
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
          disabled={isInitialLoading}
        />
        <button
          onClick={handleSearch}
          disabled={isInitialLoading}
          style={{
            padding: '8px 16px',
            backgroundColor: '#0088FE',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: isInitialLoading ? 'not-allowed' : 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          {isInitialLoading ? (
            <>
              <Loader2 className = 'animate-spin h-4 w-4'/>
              Searching...
            </>
          ) : (
            'Search'
          )}
        </button>
      </div>
      <p style = {{fontSize:"9px", marginBottom: '20 px', textAlign: "center"}}> *Enclose your keyword in double quotes for stricter matching (e.g., "Viet Nam muon nam") </p>
      {/* Download and Filter Controls - Always visible */}
      <div style={{ display: 'flex', gap: '10px', justifyContent: "center", marginBottom: '20px' }}>

        {sentimentData.length > 0 && (
          <div className="relative">
            <select
              value={timeFilter}
              onChange={handleTimeFilterChange}
              disabled={isInitialLoading || isUpdateLoading}
              style={{
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                opacity: (isInitialLoading || isUpdateLoading) ? 0.7 : 1
              }}
            >
              <option value="all">All Time</option>
              <option value="yesterday">Yesterday</option>
              <option value="last_month">Last Month</option>
              <option value="last_year">Last Year</option>
            </select>
            {isUpdateLoading && (
              <div style={{
                position: 'absolute',
                top: '50%',
                right: '-24px',
                transform: 'translateY(-50%)'
              }}>
                <Loader/>
              </div>
            )}
          </div>
        )}

        {csvData && (
          <button
            onClick={downloadCSV}
            disabled={isInitialLoading || isUpdateLoading}
            style={{
              padding: '8px 16px',
              backgroundColor: '#0B4B97',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: (isInitialLoading || isUpdateLoading) ? 'not-allowed' : 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              opacity: (isInitialLoading || isUpdateLoading) ? 0.7 : 1
            }}
          >
            ⬇️ Download Full Data
          </button>
        )}

      </div>

      {/* Content Area */}
      {isInitialLoading ? (
        <div className="flex items-center justify-center h-64">
          <Loader/>
        </div>
      ) : (
        <>

          {/* Add Line Chart after Pie Chart */}
          {sentimentData.length > 0 && (
            <div style={{
              display: 'flex',
              opacity: isUpdateLoading ? 0.2 : 1,
              marginTop: '20px',
              marginBottom: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              width: "100%"
            }}>
              <ResponsiveContainer width="100%" height={500}>
                <LineChart
                  data={timeSeriesData}
                  margin={{
                    top: 5,
                    right: 70,
                    left: 60,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    angle={-45}
                    textAnchor="end"
                    height={70}
                    interval={timeFilter === 'yesterday' ? 2 : 0} // Show every third hour for yesterday view
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis
                    label={{
                      value: 'Percentage',
                      angle: -90,
                      position: 'insideLeft',
                      style: { textAnchor: 'middle' }
                    }}
                  />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="POS"
                    stroke={SENTIMENT_COLORS.POS}
                    strokeWidth={2}
                    dot={{ r: 4 }}
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    type="monotone"
                    dataKey="NEG"
                    stroke={SENTIMENT_COLORS.NEG}
                    strokeWidth={2}
                    dot={{ r: 4 }}
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    type="monotone"
                    dataKey="NEU"
                    stroke={SENTIMENT_COLORS.NEU}
                    strokeWidth={2}
                    dot={{ r: 4 }}
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', alignItems: 'center', width: '100%', marginBottom: '60px' }}>
              {sentimentData.length > 0 && (
                <div style={{ marginLeft: '40px',marginRight:"150px", flex: '0 0 400px', display: 'flex', justifyContent: 'center', opacity: isUpdateLoading ? 0.2 : 1 }}>
                  <PieChart width={400} height={400}>
                    <Pie
                      data={sentimentData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={150}
                      fill="#8884d8"
                      label={({ value }) => `${((value / total) * 100).toFixed(1)}%`}
                    >
                      {sentimentData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </div>
              )}


              {wordCloudImage && (
                <div style={{ flex: '0 0 600px', display: 'flex', padding: '0 40px', justifyContent: 'center', alignItems: 'center', opacity: isUpdateLoading ? 0.2 : 1 }}>
                    <img src={`data:image/png;base64,${wordCloudImage}`} alt="No data founded" />
                </div>
              )}

          </div>

          {insight && (
                <div style = {{marginBottom: '50px', opacity: isUpdateLoading ? 0.2 : 1}}>
                    <h1>AI Insights: </h1>
                    <pre>{insight}</pre>
                </div>
              )}

          {datatable.length > 0 && (
              <div className="row" style={{ opacity: isUpdateLoading ? 0.2 : 1 }}>
                {/* Neutral Posts Table */}
                <div className="table-container">
                  <div className="column">
                    <table>
                      <thead>
                        <tr>
                          <td colSpan="4" style={{textAlign:"center",fontSize:"18px", backgroundColor:"transparent", color:"purple", fontWeight: "bold", padding:"10px"}}>NEUTRAL POSTS</td>
                        </tr>
                        <tr>
                          <th>ID</th>
                          <th>TITLE</th>
                          <th>SCORE</th>
                          <th>SUBREDDIT</th>
                          <th>POST TIME</th>
                          <th>PERMALINK</th>
                          <th>SENTIMENT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datatable
                          .filter(row => row.sentiment === 'NEU')
                          .sort((a, b) => b.score - a.score)
                          .map((row, index) => (
                            <tr key={index}>
                              <td>{row.ID}</td>
                              <td>{row.Title.length > 20 ? row.Title.slice(0, 20) + "..." : row.Title}</td>
                              <td>{row.score}</td>
                              <td>{row.Subreddit}</td>
                              <td>{row.Time.slice(0,25)}</td>
                              <td>{row.sentiment}</td>
                              <td>
                                <a href={row.URL} target="_blank" rel="noopener noreferrer">
                                  Link
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Positive Posts Table */}
                <div className="table-container">
                  <div className="column">
                    <table>

                      <thead>
                        <tr>
                          <td colSpan="4" style={{textAlign:"center",fontSize:"18px", backgroundColor:"transparent", color:"purple", fontWeight: "bold", padding:"10px"}}>POSITIVE POSTS</td>
                        </tr>
                        <tr>

                          <th>ID</th>
                          <th>TITLE</th>
                          <th>SCORE</th>
                          <th>SUBREDDIT</th>
                          <th>POST TIME</th>
                          <th>PERMALINK</th>
                          <th>SENTIMENT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datatable
                          .filter(row => row.sentiment === 'POS')
                          .sort((a, b) => b.score - a.score)
                          .map((row, index) => (
                            <tr key={index}>
                              <td>{row.ID}</td>
                              <td>{row.Title.length > 20 ? row.Title.slice(0, 20) + "..." : row.Title}</td>
                              <td>{row.score}</td>
                              <td>{row.Subreddit}</td>
                              <td>{row.Time.slice(0,25)}</td>
                              <td>{row.sentiment}</td>
                              <td>
                                <a href={row.URL} target="_blank" rel="noopener noreferrer">
                                  Link
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Negative Posts Table */}
                <div className="table-container">
                  <div className="column">
                    <table>
                      <thead>
                        <tr>
                          <td colSpan="4" style={{textAlign:"center",fontSize:"18px", backgroundColor:"transparent", color:"purple", fontWeight: "bold", padding:"10px"}}>NEGATIVE POSTS</td>
                        </tr>
                        <tr>
                          <th>ID</th>
                          <th>TITLE</th>
                          <th>SCORE</th>
                          <th>SUBREDDIT</th>
                          <th>POST TIME</th>
                          <th>PERMALINK</th>
                          <th>SENTIMENT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datatable
                          .filter(row => row.sentiment === 'NEG')
                          .sort((a, b) => b.score - a.score)
                          .map((row, index) => (
                            <tr key={index}>
                              <td>{row.ID}</td>
                              <td>{row.Title.length > 20 ? row.Title.slice(0, 20) + "..." : row.Title}</td>
                              <td>{row.score}</td>
                              <td>{row.Subreddit}</td>
                              <td>{row.Time.slice(0,25)}</td>
                              <td>{row.sentiment}</td>
                              <td>
                                <a href={row.URL} target="_blank" rel="noopener noreferrer">
                                  Link
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          )}
        </>
      )}
      <Text/>
    </div>
  );
};



export default App;
